import React from 'react';
import SEO from '../components/seo';
import ErrorSec from '../containers/Error';
import { ResetCSS } from 'common/src/assets/css/style';
import { loadStripe } from '@stripe/stripe-js';

// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a Layout component.

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ResetCSS />
    <ErrorSec></ErrorSec>
  </>
);

export default NotFoundPage;
